import React from 'react';
import atomize from "@quarkly/atomize";

const PrivacyPolicy = props => <div {...props} style={{
	maxWidth: 800,
	margin: "auto"
}}>
	  
	<div>
		<span data-custom-class="title">
			<strong>
				PRIVACY POLICY
			</strong>
		</span>
	</div>
	  
	<div>
		 
	</div>
	  
	<div>
		<span data-custom-class="subtitle">
			<strong>
				Last updated July 10, 2024
			</strong>
		</span>
	</div>
	  
	<div>
		 
	</div>
	  
	<div>
		 
	</div>
	  
	<div>
		 
	</div>
	  
	<div>
		<span data-custom-class="body_text">
			This privacy notice for my architect help ('
			<strong>
				we
			</strong>
			', '
			<strong>
				us
			</strong>
			', or '
			<strong>
				our
			</strong>
			'), describes how and why we might collect, store, use, and/or share ('
			<strong>
				process
			</strong>
			') your information when you use our services ('
			<strong>
				Services
			</strong>
			'), such as when you:
		</span>
	</div>
	  
	<ul>
		    
		<li data-custom-class="body_text">
			<span data-custom-class="body_text">
				Visit our website at 
			</span>
			<span data-custom-class="body_text">
				 
				<a href="http://www.myarchitecthelp.com" target="_blank" data-custom-class="link">
					http://www.myarchitecthelp.com
				</a>
				 
			</span>
			<span data-custom-class="body_text">
				, or any website of ours that links to this privacy notice
			</span>
		</li>
		  
	</ul>
	  
	<div>
		    
		<div>
			 
		</div>
		    
		<ul>
			      
			<li data-custom-class="body_text">
				<span data-custom-class="body_text">
					Engage with us in other related ways, including any sales, marketing, or events
				</span>
			</li>
			    
		</ul>
		    
		<div>
			<span data-custom-class="body_text">
				<strong>
					Questions or concerns? 
				</strong>
				Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at info@myarchitecthelp.com.
			</span>
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			<span data-custom-class="heading_1">
				<strong>
					SUMMARY OF KEY POINTS
				</strong>
			</span>
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			<em>
				<span data-custom-class="body_text">
					<strong>
						This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our 
					</strong>
				</span>
			</em>
			<a href="#toc" data-custom-class="link">
				<em>
					<span data-custom-class="body_text">
						<strong>
							table of contents
						</strong>
					</span>
				</em>
			</a>
			<em>
				<span data-custom-class="body_text">
					<strong>
						 below to find the section you are looking for.
					</strong>
				</span>
			</em>
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			<span data-custom-class="body_text">
				<strong>
					What personal information do we process?
				</strong>
				 When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features you use. Learn more about 
			</span>
			<a href="#personalinfo" data-custom-class="link">
				<span data-custom-class="body_text">
					personal information you disclose to us
				</span>
			</a>
			<span data-custom-class="body_text">
				.
			</span>
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			<span data-custom-class="body_text">
				<strong>
					Do we process any sensitive personal information?
				</strong>
				 We do not process sensitive personal information.
			</span>
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			<span data-custom-class="body_text">
				<strong>
					Do we collect any information from third parties?
				</strong>
				 We do not collect any information from third parties.
			</span>
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			<span data-custom-class="body_text">
				<strong>
					How do we process your information?
				</strong>
				 We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about 
			</span>
			<a href="#infouse" data-custom-class="link">
				<span data-custom-class="body_text">
					how we process your information
				</span>
			</a>
			<span data-custom-class="body_text">
				.
			</span>
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			<span data-custom-class="body_text">
				<strong>
					In what situations and with which parties do we share personal information?
				</strong>
				 We may share information in specific situations and with specific third parties. Learn more about 
			</span>
			<a href="#whoshare" data-custom-class="link">
				<span data-custom-class="body_text">
					when and with whom we share your personal information
				</span>
			</a>
			<span data-custom-class="body_text">
				.
			</span>
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			<span data-custom-class="body_text">
				<strong>
					How do we keep your information safe?
				</strong>
				 We have organisational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about 
			</span>
			<a href="#infosafe" data-custom-class="link">
				<span data-custom-class="body_text">
					how we keep your information safe
				</span>
			</a>
			<span data-custom-class="body_text">
				.
			</span>
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			<span data-custom-class="body_text">
				<strong>
					What are your rights?
				</strong>
				 Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about 
			</span>
			<a href="#privacyrights" data-custom-class="link">
				<span data-custom-class="body_text">
					your privacy rights
				</span>
			</a>
			<span data-custom-class="body_text">
				.
			</span>
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			<span data-custom-class="body_text">
				<strong>
					How do you exercise your rights?
				</strong>
				 The easiest way to exercise your rights is by submitting a 
			</span>
			<a href="https://app.termly.io/notify/2355fac8-0d52-4df3-ace3-933bf527a0ee" target="_blank" rel="noopener noreferrer" data-custom-class="link">
				<span data-custom-class="body_text">
					data subject access request
				</span>
			</a>
			<span data-custom-class="body_text">
				 , or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.
			</span>
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			<span data-custom-class="body_text">
				Want to learn more about what we do with any information we collect? 
			</span>
			<a href="#toc" data-custom-class="link">
				<span data-custom-class="body_text">
					Review the privacy notice in full
				</span>
			</a>
			<span data-custom-class="body_text">
				.
			</span>
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			 
		</div>
		    
		<div id="toc">
			<span data-custom-class="heading_1">
				<strong>
					TABLE OF CONTENTS
				</strong>
			</span>
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			<a href="#infocollect" data-custom-class="link">
				1. WHAT INFORMATION DO WE COLLECT?
			</a>
		</div>
		    
		<div>
			<a href="#infouse" data-custom-class="link">
				2. HOW DO WE PROCESS YOUR INFORMATION?
			</a>
		</div>
		    
		<div>
			<a href="#legalbases" data-custom-class="link">
				3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?
			</a>
		</div>
		    
		<div>
			<a href="#whoshare" data-custom-class="link">
				4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
			</a>
		</div>
		    
		<div>
			<a href="#cookies" data-custom-class="link">
				5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
			</a>
		</div>
		    
		<div>
			<a href="#inforetain" data-custom-class="link">
				6. HOW LONG DO WE KEEP YOUR INFORMATION?
			</a>
		</div>
		    
		<div>
			<a href="#infosafe" data-custom-class="link">
				7. HOW DO WE KEEP YOUR INFORMATION SAFE?
			</a>
		</div>
		    
		<div>
			<a href="#infominors" data-custom-class="link">
				8. DO WE COLLECT INFORMATION FROM MINORS?
			</a>
		</div>
		    
		<div>
			<a href="#privacyrights" data-custom-class="link">
				9. WHAT ARE YOUR PRIVACY RIGHTS?
			</a>
		</div>
		    
		<div>
			<a href="#DNT" data-custom-class="link">
				10. CONTROLS FOR DO-NOT-TRACK FEATURES
			</a>
		</div>
		    
		<div>
			<a href="#uslaws" data-custom-class="link">
				11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
			</a>
		</div>
		    
		<div>
			<a href="#otherlaws" data-custom-class="link">
				12. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
			</a>
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			<a href="#policyupdates" data-custom-class="link">
				13. DO WE MAKE UPDATES TO THIS NOTICE?
			</a>
		</div>
		    
		<div>
			<a href="#contact" data-custom-class="link">
				14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
			</a>
		</div>
		    
		<div>
			<a href="#request" data-custom-class="link">
				15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
			</a>
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			 
		</div>
		    
		<div id="infocollect">
			<span id="control" data-custom-class="heading_1">
				<strong>
					1. WHAT INFORMATION DO WE COLLECT?
				</strong>
			</span>
		</div>
		    
		<div>
			 
		</div>
		    
		<div id="personalinfo">
			<span data-custom-class="heading_2">
				<strong>
					Personal information you disclose to us
				</strong>
			</span>
		</div>
		    
		<div>
			      
			<div>
				 
			</div>
			      
			<div>
				<em>
					<span data-custom-class="body_text">
						<strong>
							In Short: 
						</strong>
						We collect personal information that you provide to us.
					</span>
				</em>
			</div>
			    
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			<span data-custom-class="body_text">
				We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in activities on the Services, or otherwise when you contact us.
			</span>
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			<span data-custom-class="body_text">
				<strong>
					Personal Information Provided by You.
				</strong>
				 The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal information we collect may include the following:
			</span>
		</div>
		    
		<ul>
			      
			<li data-custom-class="body_text">
				<span data-custom-class="body_text">
					email addresses
				</span>
			</li>
			    
		</ul>
		    
		<div>
			 
		</div>
		    
		<ul>
			      
			<li data-custom-class="body_text">
				<span data-custom-class="body_text">
					contact preferences
				</span>
			</li>
			    
		</ul>
		    
		<div>
			 
		</div>
		    
		<ul>
			      
			<li data-custom-class="body_text">
				<span data-custom-class="body_text">
					billing addresses
				</span>
			</li>
			    
		</ul>
		    
		<div>
			 
		</div>
		    
		<ul>
			      
			<li data-custom-class="body_text">
				<span data-custom-class="body_text">
					debit/credit card numbers
				</span>
			</li>
			    
		</ul>
		    
		<div>
			 
		</div>
		    
		<ul>
			      
			<li data-custom-class="body_text">
				<span data-custom-class="body_text">
					names
				</span>
			</li>
			    
		</ul>
		    
		<div>
			 
		</div>
		    
		<div id="sensitiveinfo">
			<span data-custom-class="body_text">
				<strong>
					Sensitive Information.
				</strong>
				 We do not process sensitive information.
			</span>
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			<span data-custom-class="body_text">
				<strong>
					Payment Data.
				</strong>
				 We may collect data necessary to process your payment if you choose to make purchases, such as your payment instrument number, and the security code associated with your payment instrument. All payment data is handled and stored by my architect help . You may find their privacy notice link(s) here: 
			</span>
			<span data-custom-class="body_text">
				<a href="https://myarchitecthelp.com/privacy-policy" target="_blank" data-custom-class="link">
					https://myarchitecthelp.com/privacy-policy
				</a>
				 
			</span>
			<span data-custom-class="body_text">
				.
			</span>
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			<span data-custom-class="body_text">
				All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.
			</span>
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			<span data-custom-class="heading_2">
				<strong>
					Information automatically collected
				</strong>
			</span>
		</div>
		    
		<div>
			      
			<div>
				 
			</div>
			      
			<div>
				<em>
					<span data-custom-class="body_text">
						<strong>
							In Short: 
						</strong>
						Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
					</span>
				</em>
			</div>
			    
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			<span data-custom-class="body_text">
				We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
			</span>
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			<span data-custom-class="body_text">
				Like many businesses, we also collect information through cookies and similar technologies.
			</span>
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			<span data-custom-class="body_text">
				The information we collect includes:
			</span>
		</div>
		    
		<ul>
			      
			<li data-custom-class="body_text">
				<em>
					<span data-custom-class="body_text">
						Log and Usage Data.
					</span>
				</em>
				<span data-custom-class="body_text">
					 Log and usage data is service-related, diagnostic, usage, and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type, and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches, and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps'), and hardware settings).
				</span>
			</li>
			    
		</ul>
		    
		<div>
			 
		</div>
		    
		<ul>
			      
			<li data-custom-class="body_text">
				<em>
					<span data-custom-class="body_text">
						Device Data.
					</span>
				</em>
				<span data-custom-class="body_text">
					 We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier, operating system, and system configuration information.
				</span>
			</li>
			    
		</ul>
		    
		<div>
			 
		</div>
		    
		<ul>
			      
			<li data-custom-class="body_text">
				<em>
					<span data-custom-class="body_text">
						Location Data.
					</span>
				</em>
				<span data-custom-class="body_text">
					 We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you choose to opt out, you may not be able to use certain aspects of the Services.
				</span>
			</li>
			    
		</ul>
		    
		<div>
			 
		</div>
		    
		<div>
			 
		</div>
		    
		<div id="infouse">
			<span id="control" data-custom-class="heading_1">
				<strong>
					2. HOW DO WE PROCESS YOUR INFORMATION?
				</strong>
			</span>
		</div>
		    
		<div>
			      
			<div>
				 
			</div>
			      
			<div>
				<em>
					<span data-custom-class="body_text">
						<strong>
							In Short: 
						</strong>
						We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your information for other purposes with your consent.
					</span>
				</em>
			</div>
			    
		</div>
		    
		<div>
			 
		</div>
		    
		<div>
			      
			<p>
				<span data-custom-class="body_text">
					<strong>
						We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
					</strong>
				</span>
			</p>
			      
			<div>
				        
				<div>
					 
				</div>
				        
				<ul>
					          
					<li data-custom-class="body_text">
						<span data-custom-class="body_text">
							<strong>
								To deliver and facilitate delivery of services to the user. 
							</strong>
							We may process your information to provide you with the requested service.
						</span>
					</li>
					        
				</ul>
				        
				<div>
					          
					<div>
						 
					</div>
					          
					<ul>
						            
						<li>
							<span data-custom-class="body_text">
								<strong>
									To respond to user inquiries/offer support to users. 
								</strong>
								We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.
							</span>
						</li>
						          
					</ul>
					          
					<div>
						            
						<div>
							 
						</div>
						            
						<ul>
							              
							<li data-custom-class="body_text">
								<span data-custom-class="body_text">
									<strong>
										To send administrative information to you. 
									</strong>
									We may process your information to send you details about our products and services, changes to our terms and policies, and other similar information.
								</span>
							</li>
							            
						</ul>
						            
						<div>
							              
							<div>
								 
							</div>
							              
							<ul>
								                
								<li data-custom-class="body_text">
									<span data-custom-class="body_text">
										<strong>
											To fulfil and manage your orders.
										</strong>
										 We may process your information to fulfil and manage your orders, payments, returns, and exchanges made through the Services.
									</span>
								</li>
								              
							</ul>
							              
							<p>
								 
							</p>
							              
							<p>
								 
							</p>
							              
							<ul>
								                
								<li data-custom-class="body_text">
									<span data-custom-class="body_text">
										<strong>
											To enable user-to-user communications. 
										</strong>
										We may process your information if you choose to use any of our offerings that allow for communication with another user.
									</span>
								</li>
								              
							</ul>
							              
							<p>
								 
							</p>
							              
							<p>
								 
							</p>
							              
							<ul>
								                
								<li data-custom-class="body_text">
									<span data-custom-class="body_text">
										<strong>
											To request feedback. 
										</strong>
										We may process your information when necessary to request feedback and to contact you about your use of our Services.
									</span>
								</li>
								              
							</ul>
							              
							<div>
								                
								<div>
									                  
									<div>
										                    
										<div>
											                      
											<div>
												                        
												<div>
													                          
													<div>
														 
													</div>
													                          
													<ul>
														                            
														<li data-custom-class="body_text">
															<span data-custom-class="body_text">
																<strong>
																	To protect our Services.
																</strong>
																 We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.
															</span>
														</li>
														                          
													</ul>
													                          
													<div>
														                            
														<div>
															                              
															<div>
																                                
																<div>
																	 
																</div>
																                                
																<ul>
																	                                  
																	<li data-custom-class="body_text">
																		<span data-custom-class="body_text">
																			<strong>
																				To identify usage trends.
																			</strong>
																			 We may process information about how you use our Services to better understand how they are being used so we can improve them.
																		</span>
																	</li>
																	                                
																</ul>
																                                
																<div>
																	                                  
																	<div>
																		                                    
																		<div>
																			                                      
																			<div>
																				 
																			</div>
																			                                      
																			<ul>
																				                                        
																				<li data-custom-class="body_text">
																					<span data-custom-class="body_text">
																						<strong>
																							To save or protect an individual's vital interest.
																						</strong>
																						 We may process your information when necessary to save or protect an individual’s vital interest, such as to prevent harm.
																					</span>
																				</li>
																				                                      
																			</ul>
																			                                      
																			<div>
																				 
																			</div>
																			                                      
																			<div>
																				 
																			</div>
																			                                      
																			<div id="legalbases">
																				<span data-custom-class="heading_1">
																					<strong>
																						3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?
																					</strong>
																				</span>
																			</div>
																			                                      
																			<div>
																				 
																			</div>
																			                                      
																			<div>
																				<em>
																					<span data-custom-class="body_text">
																						<strong>
																							In Short: 
																						</strong>
																						We only process your personal information when we believe it is necessary and we have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your consent, to comply with laws, to provide you with services to enter into or fulfil our contractual obligations, to protect your rights, or to fulfil our legitimate business interests.
																					</span>
																				</em>
																			</div>
																			                                      
																			<div>
																				 
																			</div>
																			                                      
																			<div>
																				 
																			</div>
																			                                      
																			<div>
																				<em>
																					<span data-custom-class="body_text">
																						<strong>
																							<u>
																								If you are located in the EU or UK, this section applies to you.
																							</u>
																						</strong>
																					</span>
																				</em>
																			</div>
																			                                      
																			<div>
																				 
																			</div>
																			                                      
																			<div>
																				 
																			</div>
																			                                      
																			<div>
																				<span data-custom-class="body_text">
																					The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal bases we rely on in order to process your personal information. As such, we may rely on the following legal bases to process your personal information:
																				</span>
																			</div>
																			                                      
																			<ul>
																				                                        
																				<li data-custom-class="body_text">
																					<span data-custom-class="body_text">
																						<strong>
																							Consent. 
																						</strong>
																						We may process your information if you have given us permission (i.e. consent) to use your personal information for a specific purpose. You can withdraw your consent at any time. Learn more about 
																					</span>
																					<a href="#withdrawconsent" data-custom-class="link">
																						<span data-custom-class="body_text">
																							withdrawing your consent
																						</span>
																					</a>
																					<span data-custom-class="body_text">
																						.
																					</span>
																				</li>
																				                                      
																			</ul>
																			                                      
																			<div>
																				 
																			</div>
																			                                      
																			<ul>
																				                                        
																				<li data-custom-class="body_text">
																					<span data-custom-class="body_text">
																						<strong>
																							Performance of a Contract.
																						</strong>
																						 We may process your personal information when we believe it is necessary to fulfil our contractual obligations to you, including providing our Services or at your request prior to entering into a contract with you.
																					</span>
																				</li>
																				                                      
																			</ul>
																			                                      
																			<div>
																				 
																			</div>
																			                                      
																			<ul>
																				                                        
																				<li data-custom-class="body_text">
																					<span data-custom-class="body_text">
																						<strong>
																							Legitimate Interests.
																						</strong>
																						 We may process your information when we believe it is reasonably necessary to achieve our legitimate business interests and those interests do not outweigh your interests and fundamental rights and freedoms. For example, we may process your personal information for some of the purposes described in order to:
																					</span>
																				</li>
																				                                      
																			</ul>
																			                                      
																			<div>
																				                                        
																				<div>
																					                                          
																					<div>
																						 
																					</div>
																					                                          
																					<ul>
																						                                            
																						<li data-custom-class="body_text">
																							                                              
																							<p>
																								<span data-custom-class="body_text">
																									Analyse how our Services are used so we can improve them to engage and retain users
																								</span>
																							</p>
																							                                            
																						</li>
																						                                          
																					</ul>
																					                                          
																					<div>
																						                                            
																						<div>
																							 
																						</div>
																						                                            
																						<ul>
																							                                              
																							<li data-custom-class="body_text">
																								                                                
																								<p>
																									<span data-custom-class="body_text">
																										Diagnose problems and/or prevent fraudulent activities
																									</span>
																								</p>
																								                                              
																							</li>
																							                                            
																						</ul>
																						                                            
																						<div>
																							 
																						</div>
																						                                            
																						<ul>
																							                                              
																							<li data-custom-class="body_text">
																								                                                
																								<p>
																									<span data-custom-class="body_text">
																										Understand how our users use our products and services so we can improve user experience
																									</span>
																								</p>
																								                                              
																							</li>
																							                                            
																						</ul>
																						                                            
																						<div>
																							 
																						</div>
																						                                            
																						<ul>
																							                                              
																							<li data-custom-class="body_text">
																								<span data-custom-class="body_text">
																									<strong>
																										Legal Obligations.
																									</strong>
																									 We may process your information where we believe it is necessary for compliance with our legal obligations, such as to cooperate with a law enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your information as evidence in litigation in which we are involved.
																								</span>
																								<br />
																								 
																							</li>
																							                                            
																						</ul>
																						                                            
																						<div>
																							 
																						</div>
																						                                            
																						<ul>
																							                                              
																							<li data-custom-class="body_text">
																								<span data-custom-class="body_text">
																									<strong>
																										Vital Interests.
																									</strong>
																									 We may process your information where we believe it is necessary to protect your vital interests or the vital interests of a third party, such as situations involving potential threats to the safety of any person.
																								</span>
																							</li>
																							                                            
																						</ul>
																						                                            
																						<div>
																							 
																						</div>
																						                                            
																						<div>
																							<em>
																								<span data-custom-class="body_text">
																									<strong>
																										<u>
																											If you are located in Canada, this section applies to you.
																										</u>
																									</strong>
																								</span>
																							</em>
																						</div>
																						                                            
																						<div>
																							 
																						</div>
																						                                            
																						<div>
																							 
																						</div>
																						                                            
																						<div>
																							<span data-custom-class="body_text">
																								We may process your information if you have given us specific permission (i.e. express consent) to use your personal information for a specific purpose, or in situations where your permission can be inferred (i.e. implied consent). You can 
																							</span>
																							<a href="#withdrawconsent" data-custom-class="link">
																								<span data-custom-class="body_text">
																									withdraw your consent
																								</span>
																							</a>
																							<span data-custom-class="body_text">
																								 at any time.
																							</span>
																						</div>
																						                                            
																						<div>
																							 
																						</div>
																						                                            
																						<div>
																							<span data-custom-class="body_text">
																								In some exceptional cases, we may be legally permitted under applicable law to process your information without your consent, including, for example:
																							</span>
																						</div>
																						                                            
																						<ul>
																							                                              
																							<li data-custom-class="body_text">
																								<span data-custom-class="body_text">
																									If collection is clearly in the interests of an individual and consent cannot be obtained in a timely way
																								</span>
																							</li>
																							                                            
																						</ul>
																						                                            
																						<div>
																							 
																						</div>
																						                                            
																						<ul>
																							                                              
																							<li data-custom-class="body_text">
																								<span data-custom-class="body_text">
																									For investigations and fraud detection and prevention
																								</span>
																							</li>
																							                                            
																						</ul>
																						                                            
																						<div>
																							 
																						</div>
																						                                            
																						<ul>
																							                                              
																							<li data-custom-class="body_text">
																								<span data-custom-class="body_text">
																									For business transactions provided certain conditions are met
																								</span>
																							</li>
																							                                            
																						</ul>
																						                                            
																						<div>
																							 
																						</div>
																						                                            
																						<ul>
																							                                              
																							<li data-custom-class="body_text">
																								<span data-custom-class="body_text">
																									If it is contained in a witness statement and the collection is necessary to assess, process, or settle an insurance claim
																								</span>
																							</li>
																							                                            
																						</ul>
																						                                            
																						<div>
																							 
																						</div>
																						                                            
																						<ul>
																							                                              
																							<li data-custom-class="body_text">
																								<span data-custom-class="body_text">
																									For identifying injured, ill, or deceased persons and communicating with next of kin
																								</span>
																							</li>
																							                                            
																						</ul>
																						                                            
																						<div>
																							 
																						</div>
																						                                            
																						<ul>
																							                                              
																							<li data-custom-class="body_text">
																								<span data-custom-class="body_text">
																									If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse
																								</span>
																							</li>
																							                                            
																						</ul>
																						                                            
																						<div>
																							 
																						</div>
																						                                            
																						<ul>
																							                                              
																							<li data-custom-class="body_text">
																								<span data-custom-class="body_text">
																									If it is reasonable to expect collection and use with consent would compromise the availability or the accuracy of the information and the collection is reasonable for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province
																								</span>
																							</li>
																							                                            
																						</ul>
																						                                            
																						<div>
																							 
																						</div>
																						                                            
																						<ul>
																							                                              
																							<li data-custom-class="body_text">
																								<span data-custom-class="body_text">
																									If disclosure is required to comply with a subpoena, warrant, court order, or rules of the court relating to the production of records
																								</span>
																							</li>
																							                                            
																						</ul>
																						                                            
																						<div>
																							 
																						</div>
																						                                            
																						<ul>
																							                                              
																							<li data-custom-class="body_text">
																								<span data-custom-class="body_text">
																									If it was produced by an individual in the course of their employment, business, or profession and the collection is consistent with the purposes for which the information was produced
																								</span>
																							</li>
																							                                            
																						</ul>
																						                                            
																						<div>
																							 
																						</div>
																						                                            
																						<ul>
																							                                              
																							<li data-custom-class="body_text">
																								<span data-custom-class="body_text">
																									If the collection is solely for journalistic, artistic, or literary purposes
																								</span>
																							</li>
																							                                            
																						</ul>
																						                                            
																						<div>
																							 
																						</div>
																						                                            
																						<ul>
																							                                              
																							<li data-custom-class="body_text">
																								<span data-custom-class="body_text">
																									If the information is publicly available and is specified by the regulations
																								</span>
																							</li>
																							                                            
																						</ul>
																						                                            
																						<div>
																							 
																						</div>
																						                                            
																						<div>
																							 
																						</div>
																						                                            
																						<div id="whoshare">
																							<span id="control" data-custom-class="heading_1">
																								<strong>
																									4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
																								</strong>
																							</span>
																						</div>
																						                                            
																						<div>
																							 
																						</div>
																						                                            
																						<div>
																							<em>
																								<span data-custom-class="body_text">
																									<strong>
																										In Short:
																									</strong>
																									 We may share information in specific situations described in this section and/or with the following third parties.
																								</span>
																							</em>
																						</div>
																						                                            
																						<div>
																							 
																						</div>
																						                                            
																						<div>
																							 
																						</div>
																						                                            
																						<div>
																							<span data-custom-class="body_text">
																								We may need to share your personal information in the following situations:
																							</span>
																						</div>
																						                                            
																						<ul>
																							                                              
																							<li data-custom-class="body_text">
																								<span data-custom-class="body_text">
																									<strong>
																										Business Transfers.
																									</strong>
																									 We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
																								</span>
																							</li>
																							                                            
																						</ul>
																						                                            
																						<div>
																							                                              
																							<div>
																								                                                
																								<div>
																									                                                  
																									<div>
																										                                                    
																										<div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div id="cookies">
																												<span id="control" data-custom-class="heading_1">
																													<strong>
																														5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
																													</strong>
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<em>
																													<span data-custom-class="body_text">
																														<strong>
																															In Short:
																														</strong>
																														 We may use cookies and other tracking technologies to collect and store your information.
																													</span>
																												</em>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<span data-custom-class="body_text">
																													We may use cookies and similar tracking technologies (like web beacons and pixels) to gather information when you interact with our Services. Some online tracking technologies help us maintain the security of our Services, prevent crashes, fix bugs, save your preferences, and assist with basic site functions.
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<span data-custom-class="body_text">
																													We also permit third parties and service providers to use online tracking technologies on our Services for analytics and advertising, including to help manage and display advertisements, to tailor advertisements to your interests, or to send abandoned shopping cart reminders (depending on your communication preferences). The third parties and service providers use their technology to provide advertising about products and services tailored to your interests which may appear either on our Services or on other websites.
																												</span>
																											</div>
																											                                                      
																											<div>
																												<br />
																												 
																											</div>
																											                                                      
																											<div>
																												<span data-custom-class="body_text">
																													To the extent these online tracking technologies are deemed to be a 'sale'/'sharing' (which includes targeted advertising, as defined under the applicable laws) under applicable US state laws, you can opt out of these online tracking technologies by submitting a request as described below under section '
																												</span>
																												<a href="#uslaws" data-custom-class="link">
																													<span data-custom-class="body_text">
																														DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
																													</span>
																												</a>
																												<span data-custom-class="body_text">
																													 '
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<span data-custom-class="body_text">
																													Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div id="inforetain">
																												<span id="control" data-custom-class="heading_1">
																													<strong>
																														6. HOW LONG DO WE KEEP YOUR INFORMATION?
																													</strong>
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<em>
																													<span data-custom-class="body_text">
																														<strong>
																															In Short: 
																														</strong>
																														We keep your information for as long as necessary to fulfil the purposes outlined in this privacy notice unless otherwise required by law.
																													</span>
																												</em>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<span data-custom-class="body_text">
																													We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal requirements).
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<span data-custom-class="body_text">
																													When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div id="infosafe">
																												<span id="control" data-custom-class="heading_1">
																													<strong>
																														7. HOW DO WE KEEP YOUR INFORMATION SAFE?
																													</strong>
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<em>
																													<span data-custom-class="body_text">
																														<strong>
																															In Short: 
																														</strong>
																														We aim to protect your personal information through a system of organisational and technical security measures.
																													</span>
																												</em>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<span data-custom-class="body_text">
																													We have implemented appropriate and reasonable technical and organisational security measures designed to protect the security of any personal information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorised third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure environment.
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div id="infominors">
																												<span id="control" data-custom-class="heading_1">
																													<strong>
																														8. DO WE COLLECT INFORMATION FROM MINORS?
																													</strong>
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<em>
																													<span data-custom-class="body_text">
																														<strong>
																															In Short:
																														</strong>
																														 We do not knowingly collect data from or market to children under 18 years of age.
																													</span>
																												</em>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<span data-custom-class="body_text">
																													We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly sell such personal information. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at info@myarchitecthelp.com .
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div id="privacyrights">
																												<span id="control" data-custom-class="heading_1">
																													<strong>
																														9. WHAT ARE YOUR PRIVACY RIGHTS?
																													</strong>
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<em>
																													<span data-custom-class="body_text">
																														<strong>
																															In Short:
																														</strong>
																														  Depending on your state of residence in the US or in some regions, such as the European Economic Area (EEA), United Kingdom (UK), Switzerland, and Canada, you have rights that allow you greater access to and control over your personal information. You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.
																													</span>
																												</em>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<span data-custom-class="body_text">
																													In some regions (like the EEA, UK, Switzerland, and Canada), you have certain rights under applicable data protection laws. These may include the right (i) to request access and obtain a copy of your personal information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making. In certain circumstances, you may also have the right to object to the processing of your personal information. You can make such a request by contacting us by using the contact details provided in the section '
																												</span>
																												<a href="#contact" data-custom-class="link">
																													<span data-custom-class="body_text">
																														HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
																													</span>
																												</a>
																												<span data-custom-class="body_text">
																													 ' below.
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<span data-custom-class="body_text">
																													We will consider and act upon any request in accordance with applicable data protection laws.
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<span data-custom-class="body_text">
																													If you are located in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the right to complain to your 
																												</span>
																												<a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" target="_blank" rel="noopener noreferrer" data-custom-class="link">
																													<span data-custom-class="body_text">
																														Member State data protection authority
																													</span>
																												</a>
																												<span data-custom-class="body_text">
																													 or 
																												</span>
																												<a href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/" target="_blank" rel="noopener noreferrer" data-custom-class="link">
																													<span data-custom-class="body_text">
																														UK data protection authority
																													</span>
																												</a>
																												<span data-custom-class="body_text">
																													.
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<span data-custom-class="body_text">
																													If you are located in Switzerland, you may contact the 
																												</span>
																												<a href="https://www.edoeb.admin.ch/edoeb/en/home.html" target="_blank" rel="noopener noreferrer" data-custom-class="link">
																													<span data-custom-class="body_text">
																														Federal Data Protection and Information Commissioner
																													</span>
																												</a>
																												<span data-custom-class="body_text">
																													.
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div id="withdrawconsent">
																												<span data-custom-class="body_text">
																													<strong>
																														<u>
																															Withdrawing your consent:
																														</u>
																													</strong>
																													 If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section '
																												</span>
																												<a href="#contact" data-custom-class="link">
																													<span data-custom-class="body_text">
																														HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
																													</span>
																												</a>
																												<span data-custom-class="body_text">
																													 ' below.
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<span data-custom-class="body_text">
																													However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<span data-custom-class="body_text">
																													<strong>
																														<u>
																															Opting out of marketing and promotional communications:
																														</u>
																														 
																													</strong>
																													You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that we send, or by contacting us using the details provided in the section ' 
																												</span>
																												<a href="#contact" data-custom-class="link">
																													<span data-custom-class="body_text">
																														HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
																													</span>
																												</a>
																												<span data-custom-class="body_text">
																													 ' below. You will then be removed from the marketing lists. However, we may still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes.
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<span data-custom-class="body_text">
																													<strong>
																														<u>
																															Cookies and similar technologies:
																														</u>
																													</strong>
																													 Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies, this could affect certain features or services of our Services.
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<span data-custom-class="body_text">
																													If you have questions or comments about your privacy rights, you may email us at info@myarchitecthelp.com.
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div id="DNT">
																												<span id="control" data-custom-class="heading_1">
																													<strong>
																														10. CONTROLS FOR DO-NOT-TRACK FEATURES
																													</strong>
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<span data-custom-class="body_text">
																													Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ('DNT') feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
																												</span>
																											</div>
																											                                                      
																											<div>
																												<br />
																												 
																											</div>
																											                                                      
																											<div>
																												<span data-custom-class="body_text">
																													California law requires us to let you know how we respond to web browser DNT signals. Because there currently is not an industry or legal standard for recognising or honouring DNT signals, we do not respond to them at this time.
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div id="uslaws">
																												<span id="control" data-custom-class="heading_1">
																													<strong>
																														11. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
																													</strong>
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<em>
																													<span data-custom-class="body_text">
																														<strong>
																															In Short: 
																														</strong>
																														If you are a resident of California, Colorado, Connecticut, Delaware, Florida, Indiana, Iowa, Kentucky, Montana, New Hampshire, New Jersey, Oregon, Tennessee, Texas, Utah, or Virginia, you may have the right to request access to and receive details about the personal information we maintain about you and how we have processed it, correct inaccuracies, get a copy of, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. More information is provided below.
																													</span>
																												</em>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<span data-custom-class="heading_2">
																													<strong>
																														Categories of Personal Information We Collect
																													</strong>
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<span data-custom-class="body_text">
																													We have collected the following categories of personal information in the past twelve (12) months:
																												</span>
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<figure class="table">
																												                                                        
																												<table>
																													                                                          
																													<tbody>
																														                                                            
																														<tr>
																															                                                              
																															<td>
																																<span data-custom-class="body_text">
																																	<strong>
																																		Category
																																	</strong>
																																</span>
																															</td>
																															                                                              
																															<td>
																																<span data-custom-class="body_text">
																																	<strong>
																																		Examples
																																	</strong>
																																</span>
																															</td>
																															                                                              
																															<td>
																																<span data-custom-class="body_text">
																																	<strong>
																																		Collected
																																	</strong>
																																</span>
																															</td>
																															                                                            
																														</tr>
																														                                                            
																														<tr>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	<span data-custom-class="body_text">
																																		A. Identifiers
																																	</span>
																																</div>
																																                                                              
																															</td>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	<span data-custom-class="body_text">
																																		Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and account name
																																	</span>
																																</div>
																																                                                              
																															</td>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	 
																																</div>
																																                                                                
																																<div>
																																	<span data-custom-class="body_text">
																																		YES
																																	</span>
																																</div>
																																                                                                
																																<div>
																																	 
																																</div>
																																                                                              
																															</td>
																															                                                            
																														</tr>
																														                                                            
																														<tr>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	<span data-custom-class="body_text">
																																		B. Personal information as defined in the California Customer Records statute
																																	</span>
																																</div>
																																                                                              
																															</td>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	<span data-custom-class="body_text">
																																		Name, contact information, education, employment, employment history, and financial information
																																	</span>
																																</div>
																																                                                              
																															</td>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	 
																																</div>
																																                                                                
																																<div>
																																	<span data-custom-class="body_text">
																																		YES
																																	</span>
																																</div>
																																                                                                
																																<div>
																																	 
																																</div>
																																                                                              
																															</td>
																															                                                            
																														</tr>
																														                                                            
																														<tr>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	<span data-custom-class="body_text">
																																		C. Protected classification characteristics under state or federal law
																																	</span>
																																</div>
																																                                                              
																															</td>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	<span data-custom-class="body_text">
																																		Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data
																																	</span>
																																</div>
																																                                                              
																															</td>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	 
																																</div>
																																                                                                
																																<div data-custom-class="body_text">
																																	 
																																</div>
																																                                                                
																																<div>
																																	 
																																</div>
																																                                                              
																															</td>
																															                                                            
																														</tr>
																														                                                            
																														<tr>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	<span data-custom-class="body_text">
																																		D. Commercial information
																																	</span>
																																</div>
																																                                                              
																															</td>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	<span data-custom-class="body_text">
																																		Transaction information, purchase history, financial details, and payment information
																																	</span>
																																</div>
																																                                                              
																															</td>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	 
																																</div>
																																                                                                
																																<div data-custom-class="body_text">
																																	 
																																</div>
																																                                                                
																																<div>
																																	 
																																</div>
																																                                                              
																															</td>
																															                                                            
																														</tr>
																														                                                            
																														<tr>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	<span data-custom-class="body_text">
																																		E. Biometric information
																																	</span>
																																</div>
																																                                                              
																															</td>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	<span data-custom-class="body_text">
																																		Fingerprints and voiceprints
																																	</span>
																																</div>
																																                                                              
																															</td>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	 
																																</div>
																																                                                                
																																<div data-custom-class="body_text">
																																	 
																																</div>
																																                                                                
																																<div>
																																	 
																																</div>
																																                                                              
																															</td>
																															                                                            
																														</tr>
																														                                                            
																														<tr>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	<span data-custom-class="body_text">
																																		F. Internet or other similar network activity
																																	</span>
																																</div>
																																                                                              
																															</td>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	<span data-custom-class="body_text">
																																		Browsing history, search history, online behaviour, interest data, and interactions with our and other websites, applications, systems, and advertisements
																																	</span>
																																</div>
																																                                                              
																															</td>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	 
																																</div>
																																                                                                
																																<div data-custom-class="body_text">
																																	 
																																</div>
																																                                                                
																																<div>
																																	 
																																</div>
																																                                                              
																															</td>
																															                                                            
																														</tr>
																														                                                            
																														<tr>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	<span data-custom-class="body_text">
																																		G. Geolocation data
																																	</span>
																																</div>
																																                                                              
																															</td>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	<span data-custom-class="body_text">
																																		Device location
																																	</span>
																																</div>
																																                                                              
																															</td>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	 
																																</div>
																																                                                                
																																<div data-custom-class="body_text">
																																	 
																																</div>
																																                                                                
																																<div>
																																	 
																																</div>
																																                                                              
																															</td>
																															                                                            
																														</tr>
																														                                                            
																														<tr>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	<span data-custom-class="body_text">
																																		H. Audio, electronic, sensory, or similar information
																																	</span>
																																</div>
																																                                                              
																															</td>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	<span data-custom-class="body_text">
																																		Images and audio, video or call recordings created in connection with our business activities
																																	</span>
																																</div>
																																                                                              
																															</td>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	 
																																</div>
																																                                                                
																																<div data-custom-class="body_text">
																																	 
																																</div>
																																                                                                
																																<div>
																																	 
																																</div>
																																                                                              
																															</td>
																															                                                            
																														</tr>
																														                                                            
																														<tr>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	<span data-custom-class="body_text">
																																		I. Professional or employment-related information
																																	</span>
																																</div>
																																                                                              
																															</td>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	<span data-custom-class="body_text">
																																		Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us
																																	</span>
																																</div>
																																                                                              
																															</td>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	 
																																</div>
																																                                                                
																																<div data-custom-class="body_text">
																																	 
																																</div>
																																                                                                
																																<div>
																																	 
																																</div>
																																                                                              
																															</td>
																															                                                            
																														</tr>
																														                                                            
																														<tr>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	<span data-custom-class="body_text">
																																		J. Education Information
																																	</span>
																																</div>
																																                                                              
																															</td>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	<span data-custom-class="body_text">
																																		Student records and directory information
																																	</span>
																																</div>
																																                                                              
																															</td>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	 
																																</div>
																																                                                                
																																<div data-custom-class="body_text">
																																	 
																																</div>
																																                                                                
																																<div>
																																	 
																																</div>
																																                                                              
																															</td>
																															                                                            
																														</tr>
																														                                                            
																														<tr>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	<span data-custom-class="body_text">
																																		K. Inferences drawn from collected personal information
																																	</span>
																																</div>
																																                                                              
																															</td>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	<span data-custom-class="body_text">
																																		Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics
																																	</span>
																																</div>
																																                                                              
																															</td>
																															                                                              
																															<td>
																																                                                                
																																<div>
																																	 
																																</div>
																																                                                                
																																<div data-custom-class="body_text">
																																	<span data-custom-class="body_text">
																																		NO
																																	</span>
																																</div>
																																                                                                
																																<div>
																																	 
																																</div>
																																                                                              
																															</td>
																															                                                            
																														</tr>
																														                                                            
																														<tr>
																															                                                              
																															<td>
																																                                                                
																																<p>
																																	<span data-custom-class="body_text">
																																		L. Sensitive personal Information
																																	</span>
																																</p>
																																                                                              
																															</td>
																															                                                              
																															<td>
																																                                                                
																																<p>
																																	 
																																</p>
																																                                                              
																															</td>
																															                                                              
																															<td>
																																                                                                
																																<div data-empty="true">
																																	 
																																</div>
																																                                                                
																																<div data-custom-class="body_text" data-empty="true">
																																	NO
																																</div>
																																                                                                
																																<div data-empty="true">
																																	 
																																</div>
																																                                                              
																															</td>
																															                                                            
																														</tr>
																														                                                          
																													</tbody>
																													                                                        
																												</table>
																												                                                      
																											</figure>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<div>
																												<span data-custom-class="body_text">
																													We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of:
																												</span>
																											</div>
																											                                                      
																											<ul>
																												                                                        
																												<li data-custom-class="body_text">
																													Receiving help through our customer support channels;
																												</li>
																												                                                      
																											</ul>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<ul>
																												                                                        
																												<li data-custom-class="body_text">
																													Participation in customer surveys or contests; and
																												</li>
																												                                                      
																											</ul>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<ul>
																												                                                        
																												<li data-custom-class="body_text">
																													Facilitation in the delivery of our Services and to respond to your inquiries.
																												</li>
																												                                                      
																											</ul>
																											                                                      
																											<div>
																												<span data-custom-class="body_text">
																													We will use and retain the collected personal information as needed to provide the Services or for:
																												</span>
																											</div>
																											                                                      
																											<ul>
																												                                                        
																												<li data-custom-class="body_text">
																													<span data-custom-class="body_text">
																														Category A - As long as the user has an account with us
																													</span>
																												</li>
																												                                                      
																											</ul>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<ul>
																												                                                        
																												<li data-custom-class="body_text">
																													<span data-custom-class="body_text">
																														Category B - As long as the user has an account with us
																													</span>
																												</li>
																												                                                      
																											</ul>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<ul>
																												                                                        
																												<li data-custom-class="body_text">
																													<span data-custom-class="body_text">
																														Category C - As long as the user has an account with us
																													</span>
																												</li>
																												                                                      
																											</ul>
																											                                                      
																											<div>
																												 
																											</div>
																											                                                      
																											<ul>
																												                                                        
																												<li data-custom-class="body_text">
																													<span data-custom-class="body_text">
																														Category D - As long as the user has an account with us
																													</span>
																												</li>
																												                                                      
																											</ul>
																											                                                      
																											<div>
																												                                                        
																												<div>
																													                                                          
																													<div>
																														 
																													</div>
																													                                                          
																													<ul>
																														                                                            
																														<li data-custom-class="body_text">
																															<span data-custom-class="body_text">
																																Category G - As long as the user has an account with us
																															</span>
																														</li>
																														                                                          
																													</ul>
																													                                                          
																													<div>
																														                                                            
																														<div>
																															                                                              
																															<div>
																																                                                                
																																<div>
																																	                                                                  
																																	<div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="heading_2">
																																				<strong>
																																					Sources of Personal Information
																																				</strong>
																																			</span>
																																		</div>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="body_text">
																																				Learn more about the sources of personal information we collect in '
																																			</span>
																																			<a href="#infocollect" data-custom-class="link">
																																				<span data-custom-class="body_text">
																																					WHAT INFORMATION DO WE COLLECT?
																																				</span>
																																			</a>
																																			<span data-custom-class="body_text">
																																				 '
																																			</span>
																																		</div>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="heading_2">
																																				<strong>
																																					How We Use and Share Personal Information
																																				</strong>
																																			</span>
																																		</div>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="body_text">
																																				Learn about how we use your personal information in the section, '
																																			</span>
																																			<a href="#infouse" data-custom-class="link">
																																				HOW DO WE PROCESS YOUR INFORMATION?
																																			</a>
																																			<span data-custom-class="body_text">
																																				 '
																																			</span>
																																		</div>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="body_text">
																																				<strong>
																																					Will your information be shared with anyone else?
																																				</strong>
																																			</span>
																																		</div>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="body_text">
																																				We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Learn more about how we disclose personal information to in the section, '
																																			</span>
																																			<a href="#whoshare" data-custom-class="link">
																																				WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
																																			</a>
																																			<span data-custom-class="body_text">
																																				 '
																																			</span>
																																		</div>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="body_text">
																																				We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration. This is not considered to be 'selling' of your personal information.
																																			</span>
																																		</div>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="body_text">
																																				We have not disclosed, sold, or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. We will not sell or share personal information in the future belonging to website visitors, users, and other consumers.
																																			</span>
																																		</div>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="heading_2">
																																				<strong>
																																					Your Rights
																																				</strong>
																																			</span>
																																		</div>
																																		                                                                    
																																		<div>
																																			<br />
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="body_text">
																																				You have rights under certain US state data protection laws. However, these rights are not absolute, and in certain cases, we may decline your request as permitted by law. These rights include:
																																			</span>
																																		</div>
																																		                                                                    
																																		<ul>
																																			                                                                      
																																			<li data-custom-class="body_text">
																																				<strong>
																																					Right to know
																																				</strong>
																																				 whether or not we are processing your personal data
																																			</li>
																																			                                                                    
																																		</ul>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<ul>
																																			                                                                      
																																			<li data-custom-class="body_text">
																																				<strong>
																																					Right to access 
																																				</strong>
																																				your personal data
																																			</li>
																																			                                                                    
																																		</ul>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<ul>
																																			                                                                      
																																			<li data-custom-class="body_text">
																																				<strong>
																																					Right to correct 
																																				</strong>
																																				inaccuracies in your personal data
																																			</li>
																																			                                                                    
																																		</ul>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<ul>
																																			                                                                      
																																			<li data-custom-class="body_text">
																																				<strong>
																																					Right to request
																																				</strong>
																																				 the deletion of your personal data
																																			</li>
																																			                                                                    
																																		</ul>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<ul>
																																			                                                                      
																																			<li data-custom-class="body_text">
																																				<strong>
																																					Right to obtain a copy 
																																				</strong>
																																				of the personal data you previously shared with us
																																			</li>
																																			                                                                    
																																		</ul>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<ul>
																																			                                                                      
																																			<li data-custom-class="body_text">
																																				<strong>
																																					Right to non-discrimination
																																				</strong>
																																				 for exercising your rights
																																			</li>
																																			                                                                    
																																		</ul>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<ul>
																																			                                                                      
																																			<li data-custom-class="body_text">
																																				<strong>
																																					Right to opt out
																																				</strong>
																																				 of the processing of your personal data if it is used for targeted advertising (or sharing as defined under California’s privacy law), the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ('profiling')
																																			</li>
																																			                                                                    
																																		</ul>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="body_text">
																																				Depending upon the state where you live, you may also have the following rights:
																																			</span>
																																		</div>
																																		                                                                    
																																		<ul>
																																			                                                                      
																																			<li data-custom-class="body_text">
																																				Right to obtain a list of the categories of third parties to which we have disclosed personal data (as permitted by applicable law, including California's and Delaware's privacy law)
																																			</li>
																																			                                                                    
																																		</ul>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<ul>
																																			                                                                      
																																			<li data-custom-class="body_text">
																																				Right to obtain a list of specific third parties to which we have disclosed personal data (as permitted by applicable law, including Oregon’s privacy law)
																																			</li>
																																			                                                                    
																																		</ul>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<ul>
																																			                                                                      
																																			<li data-custom-class="body_text">
																																				Right to limit use and disclosure of sensitive personal data (as permitted by applicable law, including California’s privacy law)
																																			</li>
																																			                                                                    
																																		</ul>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<ul>
																																			                                                                      
																																			<li data-custom-class="body_text">
																																				Right to opt out of the collection of sensitive data and personal data collected through the operation of a voice or facial recognition feature (as permitted by applicable law, including Florida’s privacy law)
																																			</li>
																																			                                                                    
																																		</ul>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="heading_2">
																																				<strong>
																																					How to Exercise Your Rights
																																				</strong>
																																			</span>
																																		</div>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="body_text">
																																				To exercise these rights, you can contact us by submitting a 
																																			</span>
																																			<a href="https://app.termly.io/notify/2355fac8-0d52-4df3-ace3-933bf527a0ee" target="_blank" rel="noopener noreferrer" data-custom-class="link">
																																				data subject access request
																																			</a>
																																			<span data-custom-class="body_text">
																																				, by emailing us at info@myarchitecthelp.com, 
																																			</span>
																																			<span data-custom-class="body_text">
																																				or by referring to the contact details at the bottom of this document.
																																			</span>
																																		</div>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="body_text">
																																				Under certain US state data protection laws, you can designate an authorised agent to make a request on your behalf. We may deny a request from an authorised agent that does not submit proof that they have been validly authorised to act on your behalf in accordance with applicable laws.
																																			</span>
																																			<br />
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="heading_2">
																																				<strong>
																																					Request Verification
																																				</strong>
																																			</span>
																																		</div>
																																		                                                                    
																																		<div>
																																			<br />
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="body_text">
																																				Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. We will only use personal information provided in your request to verify your identity or authority to make the request. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying your identity and for security or fraud-prevention purposes.
																																			</span>
																																		</div>
																																		                                                                    
																																		<div>
																																			<br />
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="body_text">
																																				If you submit the request through an authorised agent, we may need to collect additional information to verify your identity before processing your request and the agent will need to provide a written and signed permission from you to submit such request on your behalf.
																																			</span>
																																		</div>
																																		                                                                    
																																		<div>
																																			<br />
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="heading_2">
																																				<strong>
																																					Appeals
																																				</strong>
																																			</span>
																																		</div>
																																		                                                                    
																																		<div>
																																			<br />
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="body_text">
																																				Under certain US state data protection laws, if we decline to take action regarding your request, you may appeal our decision by emailing us at info@myarchitecthelp.com . We will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied, you may submit a complaint to your state attorney general.
																																			</span>
																																		</div>
																																		                                                                    
																																		<div>
																																			<br />
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="heading_2">
																																				<strong>
																																					California 'Shine The Light' Law
																																				</strong>
																																			</span>
																																		</div>
																																		                                                                    
																																		<div>
																																			<br />
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="body_text">
																																				California Civil Code Section 1798.83, also known as the 'Shine The Light' law, permits our users who are California residents to request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us by using the contact details provided in the section '
																																			</span>
																																			<a href="#contact" data-custom-class="link">
																																				<span data-custom-class="body_text">
																																					HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
																																				</span>
																																			</a>
																																			<span data-custom-class="body_text">
																																				 '
																																			</span>
																																		</div>
																																		                                                                    
																																		<div>
																																			<br />
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span id="otherlaws" data-custom-class="heading_1">
																																				<strong>
																																					12. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
																																				</strong>
																																			</span>
																																		</div>
																																		                                                                    
																																		<div>
																																			<br />
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<em>
																																				<span data-custom-class="body_text">
																																					<strong>
																																						In Short:
																																					</strong>
																																					 You may have additional rights based on the country you reside in.
																																				</span>
																																			</em>
																																		</div>
																																		                                                                    
																																		<div>
																																			<br />
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="heading_2">
																																				<strong>
																																					Australia
																																				</strong>
																																				 
																																				<strong>
																																					and
																																				</strong>
																																				 
																																				<strong>
																																					New Zealand
																																				</strong>
																																			</span>
																																		</div>
																																		                                                                    
																																		<div>
																																			<br />
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="body_text">
																																				We collect and process your personal information under the obligations and conditions set by Australia's Privacy Act 1988 and New Zealand's Privacy Act 2020 (Privacy Act).
																																			</span>
																																		</div>
																																		                                                                    
																																		<div>
																																			<br />
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="body_text">
																																				This privacy notice satisfies the notice requirements defined in both Privacy Acts, in particular: what personal information we collect from you, from which sources, for which purposes, and other recipients of your personal information.
																																			</span>
																																		</div>
																																		                                                                    
																																		<div>
																																			<br />
																																			 
																																		</div>
																																		                                                                    
																																		<div>
																																			<span data-custom-class="body_text">
																																				If you do not wish to provide the personal information necessary to fulfil their applicable purpose, it may affect our ability to provide our services, in particular:
																																			</span>
																																		</div>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<ul>
																																			                                                                      
																																			<li data-custom-class="body_text">
																																				<span data-custom-class="body_text">
																																					offer you the products or services that you want
																																				</span>
																																			</li>
																																			                                                                    
																																		</ul>
																																		                                                                    
																																		<div>
																																			 
																																		</div>
																																		                                                                    
																																		<ul>
																																			                                                                      
																																			<li data-custom-class="body_text">
																																				<span data-custom-class="body_text">
																																					respond to or help with your requests
																																				</span>
																																			</li>
																																			                                                                    
																																		</ul>
																																		                                                                    
																																		<div>
																																			                                                                      
																																			<div>
																																				                                                                        
																																				<div>
																																					<span data-custom-class="body_text">
																																						At any time, you have the right to request access to or correction of your personal information. You can make such a request by contacting us by using the contact details provided in the section '
																																					</span>
																																					<a href="#request" data-custom-class="link">
																																						<span data-custom-class="link">
																																							HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
																																						</span>
																																					</a>
																																					<span data-custom-class="body_text">
																																						 '
																																					</span>
																																				</div>
																																				                                                                        
																																				<div>
																																					<br />
																																					 
																																				</div>
																																				                                                                        
																																				<div>
																																					<span data-custom-class="body_text">
																																						If you believe we are unlawfully processing your personal information, you have the right to submit a complaint about a breach of the Australian Privacy Principles to the 
																																					</span>
																																					<a href="https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us" target="_blank" rel="noopener noreferrer" data-custom-class="link">
																																						<span data-custom-class="link">
																																							Office of the Australian Information Commissioner
																																						</span>
																																					</a>
																																					<span data-custom-class="body_text">
																																						 and a breach of New Zealand's Privacy Principles to the 
																																					</span>
																																					<a href="https://www.privacy.org.nz/your-rights/making-a-complaint/" target="_blank" rel="noopener noreferrer" data-custom-class="link">
																																						<span data-custom-class="link">
																																							Office of New Zealand Privacy Commissioner
																																						</span>
																																					</a>
																																					<span data-custom-class="body_text">
																																						 .
																																					</span>
																																				</div>
																																				                                                                        
																																				<div>
																																					<br />
																																					 
																																				</div>
																																				                                                                        
																																				<div>
																																					<span data-custom-class="heading_2">
																																						<strong>
																																							Republic of South Africa
																																						</strong>
																																					</span>
																																				</div>
																																				                                                                        
																																				<div>
																																					<br />
																																					 
																																				</div>
																																				                                                                        
																																				<div>
																																					<span data-custom-class="body_text">
																																						At any time, you have the right to request access to or correction of your personal information. You can make such a request by contacting us by using the contact details provided in the section '
																																					</span>
																																					<a href="#request">
																																						<span data-custom-class="link">
																																							HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
																																						</span>
																																					</a>
																																					<span data-custom-class="body_text">
																																						 '
																																					</span>
																																				</div>
																																				                                                                        
																																				<div>
																																					<br />
																																					 
																																				</div>
																																				                                                                        
																																				<div>
																																					<span data-custom-class="body_text">
																																						If you are unsatisfied with the manner in which we address any complaint with regard to our processing of personal information, you can contact the office of the regulator, the details of which are:
																																					</span>
																																				</div>
																																				                                                                        
																																				<div>
																																					<br />
																																					 
																																				</div>
																																				                                                                        
																																				<div>
																																					<a href="https://inforegulator.org.za/" target="_blank" rel="noopener noreferrer" data-custom-class="link">
																																						<span data-custom-class="link">
																																							The Information Regulator (South Africa)
																																						</span>
																																					</a>
																																				</div>
																																				                                                                        
																																				<div>
																																					<span data-custom-class="body_text">
																																						General enquiries: 
																																					</span>
																																					<a href="mailto:enquiries@inforegulator.org.za" target="_blank" rel="noopener noreferrer" data-custom-class="link">
																																						<span data-custom-class="link">
																																							enquiries@inforegulator.org.za
																																						</span>
																																					</a>
																																				</div>
																																				                                                                        
																																				<div>
																																					<span data-custom-class="body_text">
																																						Complaints (complete POPIA/PAIA form 5): 
																																					</span>
																																					<a href="mailto:PAIAComplaints@inforegulator.org.za" target="_blank" rel="noopener noreferrer" data-custom-class="link">
																																						<span data-custom-class="link">
																																							PAIAComplaints@inforegulator.org.za
																																						</span>
																																					</a>
																																					<span data-custom-class="body_text">
																																						 & 
																																					</span>
																																					<a href="mailto:POPIAComplaints@inforegulator.org.za" target="_blank" rel="noopener noreferrer" data-custom-class="link">
																																						<span data-custom-class="link">
																																							POPIAComplaints@inforegulator.org.za
																																						</span>
																																					</a>
																																				</div>
																																				                                                                        
																																				<div>
																																					 
																																				</div>
																																				                                                                        
																																				<div id="policyupdates">
																																					<span id="control" data-custom-class="heading_1">
																																						<strong>
																																							13. DO WE MAKE UPDATES TO THIS NOTICE?
																																						</strong>
																																					</span>
																																				</div>
																																				                                                                        
																																				<div>
																																					 
																																				</div>
																																				                                                                        
																																				<div>
																																					<em>
																																						<span data-custom-class="body_text">
																																							<strong>
																																								In Short: 
																																							</strong>
																																							Yes, we will update this notice as necessary to stay compliant with relevant laws.
																																						</span>
																																					</em>
																																				</div>
																																				                                                                        
																																				<div>
																																					 
																																				</div>
																																				                                                                        
																																				<div>
																																					<span data-custom-class="body_text">
																																						We may update this privacy notice from time to time. The updated version will be indicated by an updated 'Revised' date at the top of this privacy notice. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
																																					</span>
																																				</div>
																																				                                                                        
																																				<div>
																																					 
																																				</div>
																																				                                                                        
																																				<div id="contact">
																																					<span id="control" data-custom-class="heading_1">
																																						<strong>
																																							14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
																																						</strong>
																																					</span>
																																				</div>
																																				                                                                        
																																				<div>
																																					 
																																				</div>
																																				                                                                        
																																				<div>
																																					<span data-custom-class="body_text">
																																						If you have questions or comments about this notice, you may email us at info@myarchitecthelp.com or  contact us by post at:
																																					</span>
																																				</div>
																																				                                                                        
																																				<div>
																																					 
																																				</div>
																																				                                                                        
																																				<div>
																																					<span data-custom-class="body_text">
																																						my architect help
																																					</span>
																																				</div>
																																				                                                                        
																																				<div>
																																					<span data-custom-class="body_text">
																																						__________
																																					</span>
																																				</div>
																																				                                                                        
																																				<div>
																																					<span data-custom-class="body_text">
																																						__________
																																					</span>
																																				</div>
																																				                                                                        
																																				<div>
																																					 
																																				</div>
																																				                                                                        
																																				<div id="request">
																																					<span id="control" data-custom-class="heading_1">
																																						<strong>
																																							15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
																																						</strong>
																																					</span>
																																				</div>
																																				                                                                        
																																				<div>
																																					 
																																				</div>
																																				                                                                        
																																				<div>
																																					<span data-custom-class="body_text">
																																						Based on the applicable laws of your country or state of residence in the US, you may have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. To request to review, update, or delete your personal information, please fill out and submit a  
																																					</span>
																																					<a href="https://app.termly.io/notify/2355fac8-0d52-4df3-ace3-933bf527a0ee" target="_blank" rel="noopener noreferrer" data-custom-class="link">
																																						<span data-custom-class="body_text">
																																							data subject access request
																																						</span>
																																					</a>
																																					<span data-custom-class="body_text">
																																						.
																																					</span>
																																				</div>
																																				                                                                      
																																			</div>
																																			                                                                    
																																		</div>
																																		                                                                  
																																	</div>
																																	                                                                
																																</div>
																																                                                              
																															</div>
																															                                                            
																														</div>
																														                                                          
																													</div>
																													                                                        
																												</div>
																												                                                      
																											</div>
																											                                                    
																										</div>
																										                                                  
																									</div>
																									                                                
																								</div>
																								                                              
																							</div>
																							                                            
																						</div>
																						                                          
																					</div>
																					                                        
																				</div>
																				                                      
																			</div>
																			                                    
																		</div>
																		                                  
																	</div>
																	                                
																</div>
																                              
															</div>
															                            
														</div>
														                          
													</div>
													                        
												</div>
												                      
											</div>
											                    
										</div>
										                  
									</div>
									                
								</div>
								              
							</div>
							            
						</div>
						          
					</div>
					        
				</div>
				      
			</div>
			    
		</div>
		  
	</div>
</div>;

export default atomize(PrivacyPolicy)({
	name: "PrivacyPolicy",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		// paste here description for your component
		en: "PrivacyPolicy — my awesome component"
	},
	propInfo: {
		// paste here props description for your component
		yourCustomProps: {
			control: "input"
		}
	}
});