import React, { useEffect } from 'react';
import atomize from "@quarkly/atomize";
import { FilloutStandardEmbed } from "@fillout/react";

const MainForm = props => {
	return <div {...props} id="contact">
		    
		<div style={{
			width: "100%",
			height: "100%"
		}}>
			      
			<FilloutStandardEmbed filloutId="t7dn8WzPLtus" />
			    
		</div>
		  
	</div>;
};

export default atomize(MainForm)({
	name: "MainForm",
	effects: {
		hover: ":hover"
	},
	normalize: true,
	mixins: true,
	description: {
		// paste here description for your component
		en: "MainForm — my awesome component"
	},
	propInfo: {
		// paste here props description for your component
		yourCustomProps: {
			control: "input"
		}
	}
});